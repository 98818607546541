import React, { Component } from "react";

const AnalyzeData = () => {
  return (
    <>
      <h1>Analyze Data</h1>
    </>
  );
};
export default AnalyzeData;
